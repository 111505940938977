import { graphql, Link } from 'gatsby';
import window from 'global';
import * as React from 'react';
import Layout from '../components/layout';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import Seo from '../components/seo';
import * as navStyles from '../styles/navbar.module.css';
import * as textStyles from '../styles/text.module.css';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const { thumbnail } = post.frontmatter;
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const { previous, next } = data;
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Section customStyle={{ height: '50vh', textAlign: 'center' }} backgroundImage={thumbnail}>
        <SectionContentContainer customStyle={{ left: 0 }}>
          <MainTitle text={post.frontmatter.title} style={{ fontSize: 'calc(3vw + 3vh)' }} />
        </SectionContentContainer>
      </Section>
      <Section customStyle={{ backgroundColor: 'black' }}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer customStyle={{
            width: '40%', position: 'relative', padding: '10vh 0', left: 0, right: 0,
          }}
          >
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
              style={{ borderBottom: 'solid #fff calc(0.01vh + 0.01vw)' }}
            >
              <section
                className={textStyles.mainSubtitleFont}
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <hr />
            </article>
            <br />
            <MainSubtitle text="Tags:" />
            <div style={{
              display: 'flex', justifyContent: 'flex-start', flexDirection: 'row',
            }}
            >
              {post.frontmatter.tags.map((tag) => (
                <small
                  className={textStyles.mainSubtitleFont}
                  style={{
                    fontSize: 'calc(0.6vh + 0.6vw)', fontWeight: 'bold', color: 'white', marginRight: '1vw', padding: '0.2vh 0.4vw', backgroundColor: '#707070', borderRadius: 'calc(0.2vw + 0.2vh)', fontFamily: 'PTSans-Bold',
                  }}
                >
                  {tag}
                </small>
              ))}
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{
            width: '100%', position: 'relative', padding: '10vh 6vw', left: 0, right: 0, textAlign: 'left',
          }}
          >
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
              style={{ borderBottom: 'solid #fff calc(0.01vh + 0.01vw)' }}
            >
              <section
                className={textStyles.mainSubtitleFont}
                dangerouslySetInnerHTML={{ __html: post.html }}
                itemProp="articleBody"
              />
              <hr />
            </article>
            <br />
            <MainSubtitle text="Tags:" />
            <div style={{
              display: 'flex', justifyContent: 'flex-start', flexDirection: 'row',
            }}
            >
              {post.frontmatter.tags.map((tag) => (
                <small
                  className={textStyles.mainSubtitleFont}
                  style={{
                    fontSize: 'calc(1.4vh + 1.4vw)', fontWeight: 'bold', color: 'white', marginRight: '2vw', padding: '0.5vh 2vw', backgroundColor: '#707070', borderRadius: 'calc(0.2vw + 0.2vh)', fontFamily: 'PTSans-Bold',
                  }}
                >
                  {tag}
                </small>
              ))}
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <nav className="blog-post-nav" style={{ backgroundColor: 'black' }}>
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: '0 2vw 3vh 2vw',
            marginBottom: 0,
          }}
        >
          {dimension.width > breakpoint ? (
            <>
              <li className={`${textStyles.mainSubtitleFont} ${navStyles.navLink}`} style={{ fontSize: 'calc(0.8vh + 0.8vw)' }}>
                {previous && (
                <Link to={previous.fields.slug} rel="prev" style={{ color: 'white' }}>
                  ←
                  {' '}
                  {previous.frontmatter.title}
                </Link>
                )}
              </li>
              <li className={`${textStyles.mainSubtitleFont} ${navStyles.navLink}`} style={{ fontSize: 'calc(0.8vh + 0.8vw)' }}>
                {next && (
                <Link to={next.fields.slug} rel="next" style={{ color: 'white' }}>
                  {next.frontmatter.title}
                  {' '}
                  →
                </Link>
                )}
              </li>
            </>
          ) : (
            <>
              <li className={`${textStyles.mainSubtitleFont}`} style={{ fontSize: 'calc(3vh + 3vw)' }}>
                {previous && (
                <Link to={previous.fields.slug} rel="prev" style={{ color: 'white' }}>
                  ←
                </Link>
                )}
              </li>
              <li className={`${textStyles.mainSubtitleFont}`} style={{ fontSize: 'calc(3vh + 3vw)' }}>
                {next && (
                <Link to={next.fields.slug} rel="next" style={{ color: 'white' }}>
                  →
                </Link>
                )}
              </li>
            </>
          )}
        </ul>
      </nav>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
